import React from "react";
import { ChipIcon, SupportIcon } from "@heroicons/react/solid";
import Lottie from "lottie-react";
import support from "../assets/support.json";
import { Link } from 'react-scroll';

const Support = () => {
  return (
    <div id="support" className="w-full my-20  bg-slate-200">
      <div className="max-w-[1240px] mx-auto relative">
        <div className="px-4 py-4">
          <div className="flex justify-center items-center">
            <div className="flex w-[250px] h-[250px] ">
              <Lottie animationData={support} />
            </div>
          </div>

          <h3 className="text-5xl font-bold py-2 text-center">
            Finding the right team
          </h3>
          <p className="py-4 text-3xl text-center">
            Discover our Support and Technical Assistance.
            <br /> A dedicated team ready to provide solutions for all your
            needs, ensuring a seamless experience!
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 relative gap-x-8 gap-y-8 px-4 pt-8 sm:pt-20 text-black">
          <div className="bg-white rounded-xl shadow-2xl mb-4">
            <div className="p-8">
              <SupportIcon className="w-16 p-4 bg-indigo-600  rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">👨‍💻 Technical Support</h3>
              <p className="text-gray-600 text-xl">
                Expert technical support at your fingertips - our dedicated team
                resolves your tech issues swiftly, ensuring smooth sailing for
                your devices and systems
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <Link to="contact" offset={-90} smooth={true} duration={500}>
                <p className="flex items-center text-indigo-600">
                  Contact Us 
                </p>
              </Link>
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-2xl mb-4">
            <div className="p-8">
              <ChipIcon className="w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]" />
              <h3 className="font-bold text-2xl my-6">📺 Media Inquiries</h3>
              <p className="text-gray-600 text-xl">
              For insights and updates, our dedicated media queries team is here to assist, offering prompt and comprehensive responses to your inquiries about our company
              </p>
            </div>
            <div className="bg-slate-100 pl-8 py-4">
              <Link to="contact" offset={-90} smooth={true} duration={500}>
                <p className="flex items-center text-indigo-600">
                  Contact Us 
                </p>
              </Link>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
