import React, { useState } from "react";
import Lottie from "lottie-react";
import contact from "../assets/contact.json";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import iconImg from "../assets/logo-bg.png";

const markers = [
  {
    id: 1,
    name: "Headquaters",
    position: {
      lat: 18.54714,
      lng: 73.81817,
    },
  },
];

const Contact = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <div id="contact" className="bg-slate-200 mt-12 md:flex-col">
      <div className="flex justify-center items-center">
        <div className="flex w-full md:w-[250px] h-[250px] justify-center">
          <Lottie animationData={contact} />
        </div>
      </div>
      <h1 className="text-black-100 text-5xl text-center font-bold">
        Get in Touch 🫶
      </h1>
      <h3 className="text-black-100 mt-6 text-2xl text-center">
        Your Direct Line to Our Team.
        <br /> Let's Connect and Address Your Needs Together.
      </h3>
      <div className="container mx-auto py-8">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 md:ml-4 md:mr-2">
            {/* Left section */}
            <div className="pt-16 pb-20 md:justify-center items-center">
              <div className="ml-8">
                <div className="justify-center items-center">
                  <p className="text-3xl font-bold">
                    Rudronics Technologies LLP
                  </p>
                  <p className="text-2xl">
                    S.No. 81A/2, Plot no. 1, Flat no. 7,
                  </p>
                  <p className="text-2xl">Indra Memories, Baner Road, Aundh</p>
                  <p className="text-2xl">Pune - 411007</p>
                  <div className="pt-8">
                    <p className="text-3xl font-bold">
                      Feel free to drop email 📬
                    </p>
                    <p className="text-3xl mt-1">admin@rudronics.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            {/* Right section */}
            <div className="w-full h-64 md:h-full border border-slate-300">
              {isLoaded ? (
                <GoogleMap
                  center={{
                    lat: 18.54733,
                    lng: 73.81863,
                  }}
                  zoom={17}
                  onClick={() => setActiveMarker(null)}
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {markers.map(({ id, name, position }) => (
                    <MarkerF
                      key={id}
                      position={position}
                      onClick={() => handleActiveMarker(id)}
                      icon={{
                        url: iconImg,
                        scaledSize: { width: 50, height: 80 },
                      }}
                    >
                      {activeMarker === id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                          <div>{name}</div>
                        </InfoWindowF>
                      ) : null}
                    </MarkerF>
                  ))}
                </GoogleMap>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
