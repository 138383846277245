import React, { useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Image } from "@chakra-ui/react";
import { Link } from "react-scroll";
import logo from "../assets/logo.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClicked = () => {
    setNav(!nav);
  };

  const handleButtonClick = (url) => {
    window.open(url);
    console.log("Clicked!");
  };

  return (
    <div className="w-screen h-[80px] z-10 bg-white fixed drop-shadow-lg">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <div className="justify-start mr-36">
            <Link to="home" offset={-90} smooth={true} duration={500}>
              <Image src={logo} alt="Logo" className="w-30 h-14 pl-24" />
            </Link>
          </div>
          <ul className="hidden md:flex justify-end ml-auto">
            <li className="text-md  ml-10 mr-2">
              <Link to="home" offset={-90} smooth={true} duration={500}>
                🏠 Home
              </Link>
            </li>
            <li className="text-md mr-2">
              <Link to="about" offset={-90} smooth={true} duration={500}>
                💡 About
              </Link>
            </li>
            <li className="text-md mr-2">
              <Link to="services" offset={-90} smooth={true} duration={500}>
                ⚙️ Services
              </Link>
            </li>
            <li className="text-md mr-2">
              <Link to="support" offset={-90} smooth={true} duration={500}>
                🎧 Support
              </Link>
            </li>
            <li className="text-md mr-2">
              <Link to="contact" offset={-90} smooth={true} duration={500}>
                ☎️ Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex pr-24">
          <button
            onClick={() =>
              handleButtonClick("https://www.trackinglocation.com/track/index.php")
            }
            className="border bg-blue-700 mr-4 p-3"
          >
            📡<span className="mr-2"></span> GPS Login
          </button>
        </div>
        <div className="md:hidden" onClick={handleClicked}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link to="home" offset={-90} smooth={true} duration={500}>
            🏠 Home
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link to="about" offset={-90} smooth={true} duration={500}>
            💡 About
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link to="services" offset={-90} smooth={true} duration={500}>
            ⚙️ Services
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link to="support" offset={-90} smooth={true} duration={500}>
            🎧 Support
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link to="contact" offset={-90} smooth={true} duration={500}>
            ☎️ Contact Us
          </Link>
        </li>
        <div className="border-b-2 border-zinc-300 w-full py-2 flex flex-col justify-center">
          <button
            onClick={() =>
              handleButtonClick("https://www.trackinglocation.com/track/index.php")
            }
            className="border bg-blue-700 mr-4 p-2"
          >
            GPS Server Login
          </button>
        </div>
      </ul>

      
    </div>
  );
};

export default Navbar;
