import React, { useState } from "react";
import CountUp from "react-countup";
import Lottie from 'lottie-react';
import about from '../assets/trust.json';
import ScrollTrigger from "react-scroll-trigger";

const About = () => {
  const [counterStart, setCounterStart] = useState(false);
  return (
    <div id='about'>
      <ScrollTrigger onEnter={()=> setCounterStart(true)} onExit={()=> setCounterStart(false)}>
        <div className="w-full my-28 bg-white">
          <div className="max-w-[1240px] mx-auto">
            <div className="flex justify-center items-center">
              <div className="flex w-[300px] h-[300px]">
                <Lottie animationData={about}/>
              </div>
            </div>
            
            <div className="text-center">
              <h2 className="text-5xl font-bold">
                Trusted by companies across the 🌏
              </h2>
              <h3 className="text-3xl mt-4">Pioneers in Innovation and Excellence 🙇🏻‍♂️</h3>
              <p className="text-2xl py-6 text-gray-700">
               At Rudronics, we are passionate about harnessing cutting-edge technology to drive digital transformation. With a relentless pursuit of excellence, we strive to deliver tailor-made IT solutions that empower businesses and individuals. <br/>Explore our journey as we continue to shape the future of technology.
              </p>
            </div>
            <div className="grid md:grid-cols-3 gap-1 px-2 text-center">
              
              <div className="border py-8 rounded-xl shadow-xl ">
                <p className="text-6xl font-bold text-indigo-600">
                  {counterStart && <CountUp start={0} end={7} duration={4} delay={0} />}+
                </p>
                <p className="text-gray-400 mt-2">
                  Years of Industry Experience
                </p>
              </div>

              <div className="border py-8 rounded-xl shadow-xl ">
                <p className="text-6xl font-bold text-indigo-600">
                {counterStart && <CountUp start={0} end={2000} duration={4} delay={0} />}+
                </p>
                <p className="text-gray-400 mt-2"> Devices Installed</p>
              </div>

              <div className="border py-8 rounded-xl shadow-xl ">
                <p className="text-6xl font-bold text-indigo-600">
                {counterStart && <CountUp start={0} end={100} duration={4} delay={0} />}%
                </p>
                <p className="text-gray-400 mt-2">Customer Satisfaction</p>
              </div>
            </div>
          </div>
        </div>
      </ScrollTrigger>
    </div>
  );
};

export default About;
