import React from "react";
import HeroAni from "../assets/hero.json";
import Lottie from "lottie-react";
import Typed from "react-typed";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <div
      id="home"
      className="w-full h-screen bg-white flex flex-col justify-between pt-[80px] md:pt-0"
    >
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto items-start">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-8">
          <p className="text-3xl">Transforming Possibility into Reality ✨</p>
          <p className="text-2xl my-3">Fast, Flexible, and Future-Ready! 🚀</p>
          {/* <p className='py-3 text-5xl md:text-7xl font-bold'>Cloud Management</p> */}
          <Typed
            className="py-3 text-4xl md:text-6xl font-bold"
            strings={[
              "Fleet Management",
              "GPS Tracking",
              "Web Development",
              "App Development",
            ]}
            typeSpeed={150}
            loop
          />

          <button className="py-3 px-6 sm:w-[60%] my-4">
            <Link to="contact" offset={-90} smooth={true} duration={500}>Get Started</Link>
            
          </button>
        </div>
        <div className="w-full">
          <Lottie animationData={HeroAni} />
        </div>
        <div className="absolute flex flex-col py-2 md:min-w-[760px] bottom-[5%] mx-1 md:left-1/2 transform md:-translate-x-1/2 bg-white border border-slate-300 rounded-xl text-center shadow-xl">
          <p className="font-bold">Services we offer</p>
          <div className="flex justify-between flex-wrap px-4">
            <p className="flex px-4 py-2 text-slate-500">
              <span className="mr-2">🚚</span> Fleet Management
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <span className="mr-2">🛰️</span> GPS Tracking
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <span className="mr-2">💻</span> Web Dev
            </p>
            <p className="flex px-4 py-2 text-slate-500">
              <span className="mr-2">📱</span> App Dev
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
