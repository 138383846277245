import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component loads
  }, []);

  return (
    <>
      <div className="pt-40 pl-20 pr-20 items-center justify-center">
        <p className="text-6xl font-bold mb-10">Privacy Policy</p>
        <div>
          <p className="text-xl">
            This privacy policy covers how the{" "}
            <strong>RUDRONICS TECHNOLOGIES LLP</strong> uses personal
            information collected at this site. Please read this privacy policy
            before using the site or submitting any personal information. By
            using our site, you are accepting the practices described in this
            privacy policy.
          </p>
        </div>
        <div className="mt-10">
          <p className="text-3xl font-bold">Signup of Information</p>
          <p className="text-xl mt-5">
            During Sign up , user must be provide authorized information. If{" "}
            <strong>RUDRONICS TECHNOLOGIES LLP</strong> found the any time of
            unauthorised information , our system will not responsible for it.
          </p>
        </div>
        <div className="mt-10">
          <p className="text-3xl font-bold">Collection of Information</p>
          <p className="text-xl mt-5">
            The <strong>RUDRONICS TECHNOLOGIES LLP</strong> collects personally
            identifiable information, like names, postal addresses, email
            addresses, phone number etc., when you participate in any activity,
            register for a membership, sign up for our newsletter, through
            surveys, and/or take advantage of special offers we make to our
            website visitors and clients.
          </p>
        </div>
        <div className="mt-10">
          <p className="text-3xl font-bold">Cookie/Tracking Technology</p>
          <p className="text-xl mt-5">
            The Site may use cookie and tracking technology depending on the
            features offered. Cookie and tracking technology are useful for
            gathering information such as browser type and operating system,
            tracking the number of visitors to the Site, and understanding how
            visitors use the Site. Cookies can also help customize the Site for
            visitors. Personal information cannot be collected via cookies and
            other tracking technology, however, if you previously provided
            personally identifiable information, cookies may be tied to such
            information. Aggregate cookie and tracking information may be shared
            with third parties.
            <br />
            Third party vendors, including Google, show{" "}
            <strong>RUDRONICS TECHNOLOGIES LLP</strong> ads across the Internet.
            These third party vendors use cookies to serve ads based on a user's
            prior visits to the <strong>RUDRONICS TECHNOLOGIES LLP</strong>{" "}
            website. The <strong>RUDRONICS TECHNOLOGIES LLP</strong> and any
            affiliated third party vendor do not store any personal information
            in the cookie data. Users may opt out of third-party usage of
            cookies by visiting the Network Advertising Initiative opt-out page.
          </p>
        </div>
        <div className="mt-10">
          <p className="text-3xl font-bold">Distribution of Information</p>
          <p className="text-xl mt-5">
            We may share information with governmental agencies or other
            companies assisting us in fraud prevention or investigation. We may
            do so when: <br />
            (1) permitted or required by law; or, <br />
            (2) trying to protect against or prevent actual or potential fraud
            or unauthorized transactions; or, <br />
            (3) investigating fraud which has already taken place.
            <br /> The information is not provided to these companies for
            marketing purposes.
          </p>
        </div>
        <div className="mt-10">
          <p className="text-3xl font-bold">Commitment to Data Security</p>
          <p className="text-xl mt-5">
            Your personally identifiable information is kept secure. Only
            authorized employees, agents and contractors (who have agreed to
            keep information secure and confidential) have access to this
            information. All emails and newsletters from this site allow you to
            opt out of further mailings.
          </p>
        </div>
        <div className="mt-10 mb-20">
          <p className="text-3xl font-bold">Privacy Contact Information</p>
          <p className="text-xl mt-5">
            We reserve the right to make changes to this policy. Any changes to
            this policy will be posted. If you have any questions, concerns, or
            comments about our privacy policy.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
