import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy"; // Import PrivacyPolicy component
import Hero from "./components/Hero";
import About from "./components/About";
import Services from "./components/Services";
import Support from "./components/Support";
import Contact from "./components/Contact";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          {/* Use the "element" prop */}
          <Route path="/" element={<MainPageComponent/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

const MainPageComponent = () => {
  return (
    <>
      {/* MainPageComponent content */}
      <Hero />
      <About />
      <Services />
      <Support />
      <Contact />
    </>
  );
}

export default App;






