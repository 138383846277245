import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-slate-900 text-gray-300 py-y px-2">
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">
          2023 Rudronics Technologies LLC. All rights reserved
        </p>
        <Link to="privacy-policy" className="py-4">
          Privacy Policy
        </Link>
        
      </div>
    </div>
  );
};

export default Footer;
