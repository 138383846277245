import React from "react";
import Lottie from "lottie-react";
import Car from "../assets/car.json";
import Gps from "../assets/gps.json";
import Web from "../assets/web.json";
import Mobile from "../assets/mobile.json";
import { CheckIcon } from "@heroicons/react/outline";
import "./Services.css";

const Services = () => {
  const underlineStyle = {
    textDecoration: "underline",
    textDecorationColor: "red",
    textDecorationSkipInk: "none",
    marginBottom: "4px",
  };

  return (
    <div id="services" className="w-full my-32">
      <div className="max-w-[1240px] mx-auto px-2">
        <h2 className="text-5xl font-bold text-center">All-In-1️⃣ Solution! 🎯</h2>
        <p className="text-2xl py-8 text-gray-500 text-center">
          We offer different types of services across the technical spectrum. We
          offer different types of services across the technical spectrum.
        </p>

        {/* Fleet Management */}

        <div className="grid md:grid-cols-4 gap-3">
          <div className="w-[380px] h-[380px] col-span-2 justify-center items-center ">
            <Lottie animationData={Car} />
          </div>
          <div className="justify-center items-center col-span-2 mt-8">
            <div className="mt-8">
              <p className="text-center text-5xl font-bold" style={underlineStyle}>Fleet Management</p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500 flex" />
              </div>

              <p className="text-2xl text-gray-500">
                We have over 7+ years of industry experience.
              </p>
            </div>

            <div className="flex my-8 mx-6 justify-center items-center">
              <div>
                <CheckIcon className="w-7 h-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Proactive maintenance alerts and diagnostics
              </p>
            </div>

            <div className="flex my-8 mx-6 justify-center items-center">
              <div>
                <CheckIcon className="w-7 h-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Powerful reporting and analytics tools for user.
              </p>
            </div>
          </div>
        </div>

        {/* GPS Tracking */}

        <div className="grid md:grid-cols-4 gap-3">
          <div className="col-span-2 mt-12">
            <div className="mt-8">
              <p className="text-center text-5xl font-bold" style={underlineStyle}>GPS Tracking</p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Real-time location data with interactive maps.
              </p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Geofensing and alerts for optimized in-routes.
              </p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Historical route playback for greater efficiency.
              </p>
            </div>
          </div>
          <div className="w-[400px] h-[400px] col-span-2 justify-center items-center">
            <Lottie animationData={Gps} />
          </div>
        </div>

        {/* Web Developement */}

        <div className="grid md:grid-cols-4 gap-3">
          <div className="w-[400px] h-[400px] col-span-2 justify-center items-center ">
            <Lottie animationData={Web} />
          </div>
          <div className="col-span-2">
            <div className="mt-8">
              <p className="text-center text-5xl font-bold" style={underlineStyle}>Web Development</p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Web Solutions with exceptional User experience.
              </p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Responsive Designs for a seamless integrations.
              </p>
            </div>

            <div className="flex my-8 mx-6 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Cutting-Edge, robust, modern and scalable Web.
              </p>
            </div>
          </div>
        </div>

        {/* App Development */}

        <div className="grid md:grid-cols-4 gap-3">
          <div className="col-span-2 mt-12">
            <div className="mt-8">
              <p className="text-center text-5xl font-bold mr-8" style={underlineStyle}>
                App Development
              </p>
            </div>

            <div className="flex mx-6 my-10 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Custom Apps for unique business requirements.
              </p>
            </div>

            <div className="flex mx-6 my-8 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                User-Centric Approach with the intuitive design.
              </p>
            </div>

            <div className="flex mx-6 my-8 items-center justify-center">
              <div>
                <CheckIcon className="w-7 mr-4 text-green-500" />
              </div>

              <p className="text-2xl text-gray-500">
                Building an robust, scalable & future-proof Apps.
              </p>
            </div>
          </div>
          <div className="w-[400px] h-[400px] col-span-2 justify-center items-center">
            <Lottie animationData={Mobile} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
